import * as React from "react";
import Layout from "../components/Layout";
import Seo from "../components/seo";

const TermsAndConditions = ({ location }) => {
    return (
        <Layout>
            <Seo title={"Terms and Conditions"} url={location.href} />
            <div className="container container--py terms-and-conditions">
                <h1 className="text-h2 text-fw-medium text-clr-primary gap-3x">
                    TERMS AND CONDITIONS
                </h1>
                <h2 className="text-h3 text-fw-medium text-clr-primary gap-1x">1. GENERAL</h2>
                <p className="text-p3 text-fw-regular text-clr-secondary gap-2x">
                    M/s. Sedin Technologies Private Limited, a Company registered under the Indian
                    Companies Act, 2013, and having its registered office at A1/1, 49th Street, 7th
                    Ave, Ashok Nagar, Chennai, Tamil Nadu 600083, hereinafter referred to as the
                    “Company” (where such expression shall, unless repugnant to the context thereof,
                    be deemed to include its respective legal heirs, representatives,
                    administrators, permitted successors and assigns). The Company ensures steady
                    commitment to your usage of the Platform and privacy regarding the protection of
                    your invaluable information. This document contains information about the
                    Website <a href="https://sedintechnologies.com/">www.sedintechnologies.com</a>{" "}
                    hereinafter referred to as the “Platform”).
                </p>
                <h2 className="text-h3 text-fw-medium text-clr-primary gap-1x">2. DEFINITION</h2>
                <ul className="text-p3 text-fw-regular text-clr-secondary gap-2x">
                    <li>For these Terms of Use (“Terms”), wherever the context so requires,</li>
                    <li>
                        “We”, “Our”, and “Us” shall mean and refer to the Platform and/or the
                        Company, as the context so requires.
                    </li>
                    <li>
                        “User” shall mean and refer to natural and legal individuals who use the
                        Website and who is of sound mind and competent to make sound and informed
                        decisions.
                    </li>
                    <li>
                        “Services” shall refer to the platform that provides consulting services
                        relating to technology and software development. The Services provided by
                        the Company are more fully described in Clause 4 of this Terms of Service.
                    </li>
                    <li>
                        “Third Parties” refer to any Application, legal entity or individual apart
                        from the User and the Company.
                    </li>
                    <li>
                        The term “Platform” refers to the Website which provides consulting services
                        relating to technology and software development.
                    </li>
                    <li>
                        The term “minor” shall mean and include a person who has not completed the
                        age of eighteen years.
                    </li>
                </ul>
                <h2 className="text-h3 text-fw-medium text-clr-primary gap-1x">3. GENERAL TERMS</h2>
                <ul className="text-p3 text-fw-regular text-clr-secondary gap-2x">
                    <li>
                        The headings of each section in these Terms are only for the purpose of
                        organizing the various provisions under these Terms in an orderly manner and
                        shall not be used by either Party to interpret the provisions contained
                        herein in any manner. Further, it is specifically agreed to by the Parties
                        that the headings shall have no legal or contractual value.
                    </li>
                    <li>
                        The use of this Platform by the Users is solely governed by these Terms of
                        Service, Cookie Policy, and the Privacy Policy, and any modifications or
                        amendments made thereto by the Company, from time to time, at its sole
                        discretion. If you continue to access and use this Platform, you are
                        agreeing to comply with and be bound by the following Terms and Conditions
                        of Use and other Policies mentioned above. The User expressly agrees and
                        acknowledges that these Terms of Service and other Policies are co- terminus
                        in nature and that expiry/termination of either one will lead to the
                        termination of the other.
                    </li>
                    <li>
                        The User unequivocally agrees that these Terms and the aforementioned Policy
                        constitute a legally binding agreement between the User and the Company, and
                        that the User shall be subject to the rules, guidelines, policies, terms,
                        and conditions applicable to any service that is provided by the Platform,
                        and that the same shall be deemed to be incorporated into these Terms and
                        shall be treated as part and parcel of the same. The User acknowledges and
                        agrees that no signature or express act is required to make these Terms and
                        the Policy binding on the User and that the User’s act of visiting any part
                        of the Platform constitutes the User’s full and final acceptance of these
                        Terms and the aforementioned Policy.
                    </li>
                    <li>
                        The Company reserves the sole and exclusive right to amend or modify these
                        Terms without any prior permission or intimation to the User, and the User
                        expressly agrees that any such amendments or modifications shall come into
                        effect immediately. If the User does not adhere to the changes, they must
                        stop using the Services at once. Their continuous use of the Services will
                        signify your acceptance of the changed terms.
                    </li>
                </ul>
                <h2 className="text-h3 text-fw-medium text-clr-primary gap-1x">
                    4. SERVICE OVERVIEW
                </h2>
                <p className="text-p3 text-fw-regular text-clr-secondary gap-2x">
                    The platform is used by the Company as a promotional and marketing tool for the
                    projects in which the Company is involved. The Company is involved in the
                    following business activities as services to its client on a client-to client
                    basis:
                </p>
                <ul className="text-p3 text-fw-regular text-clr-secondary gap-2x">
                    <li>Business Intelligence and Analytics</li>
                    <li>Enterprise asset management</li>
                    <li>Enterprise content management</li>
                    <li>Robotic process automation</li>
                    <li>Engineering services</li>
                    <li>Salesforce Development</li>
                    <li>Cloud infrastructure services</li>
                    <li>Web and mobile app development</li>
                    <li>Technology Consulting</li>
                    <li>E-commerce Development</li>
                </ul>
                <p className="text-p3 text-fw-regular text-clr-secondary gap-2x">
                    If a User wishes to avail of any of the services provided by the Company, and if
                    the Company and the User mutually agree on it, the terms and conditions of such
                    services shall be governed by the subsequent agreement signed by the Parties.
                </p>
                <h2 className="text-h3 text-fw-medium text-clr-primary gap-1x">5. REGISTRATION</h2>
                <p className="text-p3 text-fw-regular text-clr-secondary gap-2x">
                    The platform is used for the purpose of marketing and business tool for the
                    services provided by the Company. Therefore, all the content and features
                    available on the platform is free to the Users without any registration or
                    divulging any information. If the User wishes to contact the Company in relation
                    to the services they offer, they may do so by filling the Contact form with
                    their Name, E-mail address and Mobile Number. Users who wish to apply for
                    employment at the Company, may also do so by submitting their personal and
                    professional information in their resume or CV.
                </p>
                <h2 className="text-h3 text-fw-medium text-clr-primary gap-1x">6. ELIGIBILITY</h2>
                <ul className="text-p3 text-fw-regular text-clr-secondary gap-2x">
                    <li>
                        The User must be of sound mind and must be capable of making informed
                        decisions after referring to the Website.
                    </li>
                    <li>
                        In the event of a minor accessing the Website, it would be the
                        responsibility of his immediate guardian to monitor the minor’s use, the
                        platform or the Company will not be liable for any consequences.
                    </li>
                </ul>
                <h2 className="text-h3 text-fw-medium text-clr-primary gap-1x">7. CONTENT</h2>
                <ul className="text-p3 text-fw-regular text-clr-secondary gap-2x">
                    <li>
                        All the Content displayed including but not limited to illustrations,
                        diagrams, videos, one-liners, solutions, design, software codes, plugins,
                        and photographs on the Platform is created by the Company and is subject to
                        copyright and shall not be reused by any party (or a third party) without
                        the prior written consent of the Company and the copyright owner.
                    </li>
                    <li>
                        The Company may use or incorporate third party tools and services in a
                        collaborative manner to deliver the final result. Hence, all content
                        displayed or placed, including but not limited to illustrations, diagrams,
                        videos, one-liners, solutions, design, software codes, plugins, and
                        photographs on the platform on behalf of any third party shall be subject to
                        such third party’s copyright and shall not be reused by any party without
                        the prior written consent of the Company and the copyright owner.
                    </li>
                    <li>
                        The Users have a personal, non-exclusive, non-transferable, revocable,
                        limited privilege to access the content on the Platform. The User shall not
                        copy, adapt, and modify any content without written permission from the
                        Company
                    </li>
                    <li>
                        "Unless otherwise stated, the contents of this site including, but not
                        limited to, the text and images contained herein and their arrangement are
                        the property of Sedin Technologies. They may not be copied, reproduced,
                        republished, uploaded, posted, in any way, without the prior written consent
                        of Sedin Technologies, except that you may download, display, and print
                        solely for your personal, non-commercial use. In no format, the materials
                        shall be modified. Under certain circumstances, we reserve the right to use
                        images from the third-party free image providing platforms in accordance
                        with the creative common license agreements."
                    </li>
                </ul>
                <h2 className="text-h3 text-fw-medium text-clr-primary gap-1x">8. INDEMNITY</h2>
                <p className="text-p3 text-fw-regular text-clr-secondary gap-2x">
                    The Users undertake to indemnify the Platform and keep it indemnified against
                    all proceedings, claims, expenses, and liabilities whatsoever which may be taken
                    or made against or incurred by the Platform or by reasons of the premises for
                    any comments, feedback or any other form of content created by them, which has
                    been published by the Company on the Platform.
                </p>
                <h2 className="text-h3 text-fw-medium text-clr-primary gap-1x">
                    9. TERM &amp; TERMINATION
                </h2>
                <ul className="text-p3 text-fw-regular text-clr-secondary gap-2x">
                    <li>
                        These Terms shall continue to form a valid and binding contract between the
                        Parties and shall continue to be in full force and effect until the User
                        continues to access the Platform.
                    </li>
                    <li>
                        The Company may terminate these Terms without notice and/or suspend or
                        terminate a User’s access to the Platform at any time and for any reason, if
                        any discrepancy or legal issue arises.
                    </li>
                    <li>
                        Such suspension or termination shall not limit the Company’s right to take
                        any other action against the User that the Company considers appropriate.
                    </li>
                    <li>
                        It is also hereby declared that the Company may discontinue the Services and
                        Platforms without any prior notice.
                    </li>
                    <li>
                        The Company and its members reserve the right, in its sole discretion, to
                        unilaterally terminate the User’s access to the Platform, or any portion
                        thereof, at any time, without notice or cause.
                    </li>
                </ul>
                <h2 className="text-h3 text-fw-medium text-clr-primary gap-1x">
                    10. COMMUNICATION
                </h2>
                <ul className="text-p3 text-fw-regular text-clr-secondary gap-2x">
                    <li>
                        By using this Platform and providing their identity and contact information
                        to the Company through the Platform, the User hereby agrees and consents to
                        receive calls, e- mails, SMS or any other modes of communication from the
                        Company or any of its representatives at any time.
                    </li>
                    <li>
                        Users can report to the Company any grievances or suggestions that they have
                        through e-mail, if they find any discrepancy regarding the information
                        generated on the Platform, the Company will take necessary action after an
                        investigation. The response with resolution (if any issues are found) shall
                        be dependent on the nature of the grievance raised.
                    </li>
                </ul>
                <h2 className="text-h3 text-fw-medium text-clr-primary gap-1x">
                    11. USER OBLIGATIONS AND FORMAL UNDERTAKINGS AS TO CONDUCT
                </h2>
                <p className="text-p3 text-fw-regular text-clr-secondary gap-2x">
                    The User agrees and acknowledges that they are a restricted user of this
                    Platform and that they:
                </p>
                <ul className="text-p3 text-fw-regular text-clr-secondary gap-2x">
                    <li>
                        authorize the Platform to use, store or otherwise process certain personal
                        information and all published content, reviews and ratings, comments, and
                        feedback for personalization of services, marketing, and promotional
                        purposes and for optimization of User-related options and Services.
                    </li>
                    <li>
                        agree not to access (or attempt to access) the Platform and/or the materials
                        by any means other than through the interface provided by the Platform.
                    </li>
                    <li>
                        expressly agree and acknowledge that the Content displayed on the Platform
                        is for purpose of reference only. Under no circumstance shall the User
                        construe the same as expert/ professional advice. The User may, however,
                        report any offensive or objectionable content, which the Company may then
                        remove from the Platform, at its sole discretion.
                    </li>
                    <li>
                        expressly agrees to make good any losses suffered by the Company or platform
                        which results as a consequence of any act committed by the User including
                        but not limited to acts of fraud, misrepresentation of information provided
                        on the Company as expert advice or posting slanderous or libelous content in
                        the form of comments or feedback.
                    </li>
                    <li>
                        accepts all risk of using the forum and content on the forum. As far as the
                        law allows, the Company and its suppliers provide the forum as is, without
                        any warranty whatsoever.
                    </li>
                    <li>
                        agrees that they are using the Platform and accessing the platform at their
                        own risk with their prudent judgment and the Company or Platform shall not
                        be responsible for any resultant losses suffered.
                    </li>
                    <li>
                        agrees that the platform will be used by them solely for non-commercial use
                        with the intent of gaining services related to technology consultancy and
                        software development. This includes providing links to other platforms which
                        may or may not be competitors of the Company.
                    </li>
                    <li>
                        The User hereby expressly authorizes the Company /Platform to disclose
                        any/and all information relating to the User in the possession of the
                        Company /Platform to law enforcement or other government officials, as the
                        Company may in its sole discretion, believe necessary or appropriate in
                        connection with the investigation and/or resolution of possible crimes,
                        especially those that involve personal injury and theft/infringement of
                        intellectual property. The User further understands that the Company
                        /Platform will have to disclose any information (including the identity of
                        persons providing information or materials on the Platform) as necessary to
                        satisfy any judicial Order, law, regulation, or valid governmental request.
                    </li>
                    <li>
                        The User agrees to use the services provided by the Company, its affiliates,
                        consultants, and contracted companies, for lawful purposes only.
                    </li>
                </ul>
                <h2 className="text-h3 text-fw-medium text-clr-primary gap-1x">
                    12. BETA SERVICES AND PRODUCTS
                </h2>
                <p className="text-p3 text-fw-regular text-clr-secondary gap-2x">
                    The Company may, from time to time, offer access to certain features that are
                    classified as Beta versions. Access to and use of Beta versions may be subject
                    to additional agreements. The Company makes no representations that a Beta
                    version will ever be made generally available and reserves the right to
                    discontinue or modify a Beta version at any time without notice. Beta versions
                    are provided as is, may contain bugs, errors, or other defects, and Your use of
                    a Beta version is at Your sole risk.
                </p>
                <h2 className="text-h3 text-fw-medium text-clr-primary gap-1x">
                    13. TECHNICAL IMPROVEMENT
                </h2>
                <p className="text-p3 text-fw-regular text-clr-secondary gap-2x">
                    From time to time, Company may need to perform maintenance on or upgrade the
                    Software, Products or Company Websites or the underlying infrastructure that
                    enables you to use the Platform. This may require Company to temporarily suspend
                    or limit your use of some or all of the Platform until such time as this
                    maintenance and/or upgrade can be completed. To the extent possible and unless
                    an intervention is urgently required, Company will publish the time and date of
                    such suspension or limitation on the Company Website in advance. You will not be
                    entitled to claim damages for such suspension or limitation of the use of
                    Platform.
                </p>
                <h2 className="text-h3 text-fw-medium text-clr-primary gap-1x">
                    14. INTELLECTUAL PROPERTY RIGHTS
                </h2>
                <p className="text-p3 text-fw-regular text-clr-secondary gap-2x">
                    Unless expressly agreed to in writing, nothing contained herein shall give the
                    User a right to use any of the Platform’s Content which includes but is not
                    limited to trade names, trademarks, service marks, logos, domain names,
                    information, analytics, one-liners, reports, illustrations, photographs,
                    write-ups, solutions, videos, diagrams, and other distinctive brand features
                    according to the provisions of these Terms.
                </p>
                <h2 className="text-h3 text-fw-medium text-clr-primary gap-1x">
                    15. FORCE MAJEURE
                </h2>
                <p className="text-p3 text-fw-regular text-clr-secondary gap-2x">
                    Neither the Company nor the Platform shall be liable for damages for any delay
                    or failure to perform its obligations hereunder if such delay or failure is due
                    to a cause beyond its control or without its fault or negligence, due to Force
                    Majeure events illegal or unauthorized, including but not limited to acts of
                    war, acts of God, earthquake, riot, fire, festive activities sabotage, labor
                    shortage or dispute, internet interruption, technical failure, breakage of sea
                    cable, hacking, piracy and cheating.
                </p>
                <h2 className="text-h3 text-fw-medium text-clr-primary gap-1x">
                    16. NO WARRANTIES
                </h2>
                <p className="text-p3 text-fw-regular text-clr-secondary gap-2x">
                    You understand and agree that the services are provided &quot;as is&quot; and
                    the Company, its affiliates, suppliers, and resellers expressly disclaim all
                    warranties of any kind, express or implied, including without limitation any
                    warranty of merchantability, fitness for a particular purpose or
                    non-infringement. The Company, its affiliates, suppliers, and resellers make no
                    warranty or representation regarding the results that may be obtained from the
                    use of the services, regarding the accuracy or reliability of any information
                    obtained through the Platform or that the information on the Platform will meet
                    any user&#39;s requirements, or be uninterrupted, timely, secure or error free.
                    Use of the Platform is at your sole risk. Any material and/or data downloaded or
                    otherwise obtained using the Platform is at your own discretion and risk. You
                    will be solely responsible for any damage to you resulting from the use of the
                    Platform. The entire risk arising out of use of the Platform remains with you.
                    The Company does not assume any responsibility for the retention of any user
                    information or communications between users. The Company cannot guarantee and
                    does not promise any specific results from use of the Platform. Use is at your
                    own risk.
                </p>
                <h2 className="text-h3 text-fw-medium text-clr-primary gap-1x">
                    17. DISPUTE RESOLUTION AND JURISDICTION
                </h2>
                <p className="text-p3 text-fw-regular text-clr-secondary gap-2x">
                    It is expressly agreed to by the Parties hereto that the formation,
                    interpretation, and performance of these Terms and any disputes arising
                    therefrom will be resolved through a two-step Alternate Dispute Resolution
                    (“ADR”) mechanism. It is further agreed to by the Parties that the contents of
                    this Section shall survive even after the termination or expiry of the Terms
                    and/or Policy.
                </p>
                <ul className="text-p3 text-fw-regular text-clr-secondary gap-2x">
                    <li>
                        <b>Mediation</b>: In case of any dispute between the parties, the Parties
                        will attempt to resolve the same amicably amongst themselves, to the mutual
                        satisfaction of all parties. In the event that the Parties are unable to
                        reach such an amicable solution within thirty (30) days of one Party
                        communicating the existence of a dispute to any other Party, the dispute
                        will be resolved by arbitration, as detailed herein below.
                    </li>
                    <li>
                        <b>Arbitration</b>: In the event that the Parties are unable to amicably
                        resolve a dispute by mediation, said dispute will be referred to arbitration
                        by a sole arbitrator to be appointed by the Company, and the award passed by
                        such sole arbitrator will be valid and binding on all parties. The Parties
                        shall bear their own costs for the proceedings, although the sole arbitrator
                        may, in his/her sole discretion, direct either Party to bear the entire cost
                        of the proceedings. The arbitration shall be conducted in English, and the
                        seat of Arbitration shall be the city of Chennai, India.
                    </li>
                </ul>
                <p className="text-p3 text-fw-regular text-clr-secondary gap-2x">
                    The Parties expressly agree that the Terms, Policy, and any other agreements
                    entered into between the Parties are governed by the laws, rules, and
                    regulations of India.
                </p>
                <h2 className="text-h3 text-fw-medium text-clr-primary gap-1x">18. GRIEVANCES</h2>
                <p className="text-p3 text-fw-regular text-clr-secondary gap-2x">
                    Any and all communication relating to any dispute or grievance experienced by
                    the User may be communicated to the Company by the User by emailing to{" "}
                    <a href="mailto:marketing@sedintechnologies.com">
                        marketing@sedintechnologies.com
                    </a>
                </p>
                <h2 className="text-h3 text-fw-medium text-clr-primary gap-1x">
                    19. MISCELLANEOUS PROVISIONS
                </h2>
                <p className="text-p3 text-fw-regular text-clr-secondary gap-2x">
                    It is expressly agreed to by the Parties hereto that the formation,
                    interpretation, and performance of these Terms and any disputes arising
                    therefrom will be resolved through a two-step Alternate Dispute Resolution
                    (“ADR”) mechanism. It is further agreed to by the Parties that the contents of
                    this Section shall survive even after the termination or expiry of the Terms
                    and/or Policy.
                </p>
                <ul className="text-p3 text-fw-regular text-clr-secondary gap-2x">
                    <li>
                        <b>Entire Agreement</b>: These Terms, read with the Policies, form the
                        complete and final contract between the User and the Company with respect to
                        the subject matter hereof and supersedes all other communications,
                        representations, and agreements (whether oral, written or otherwise)
                        relating thereto.
                    </li>
                    <li>
                        <b>Waiver</b>: The failure of either Party at any time to require
                        performance of any provision of these Terms shall in no manner affect such
                        Party&#39;s right at a later time to enforce the same. No waiver by either
                        party of any breach of these Terms, whether by conduct or otherwise, in any
                        one or more instances, shall be deemed to be or construed as furtherance or
                        continuing waiver of any such breach, or a waiver of any other breach of
                        these Terms.
                    </li>
                    <li>
                        <b>Severability</b>: If any provision/clause of these Terms is held to be
                        invalid, illegal, or unenforceable by any court or authority of competent
                        jurisdiction, the validity, legality, and enforceability of the remaining
                        provisions/clauses of these Terms shall in no way be affected or impaired
                        thereby, and each such provision/clause of these Terms shall be valid and
                        enforceable to the fullest extent permitted by law. In such case, these
                        Terms shall be reformed to the minimum extent necessary to correct any
                        invalidity, illegality, or unenforceability, while preserving to the maximum
                        extent the original rights, intentions and commercial expectations of the
                        Parties hereto, as expressed herein.
                    </li>
                    <li>
                        <b>Contact Us</b>: If you have any questions about this Agreement, the
                        practices of the Platform, or your experience with the Service, you can
                        contact us at{" "}
                        <a href="mailto:marketing@sedintechnologies.com">
                            marketing@sedintechnologies.com
                        </a>
                        .
                    </li>
                </ul>
                <p className="text-p3 text-fw-regular text-clr-secondary gap-2x">
                    <b>
                        M/s. Sedin Technologies Private Limited A1/1, 49th Street, 7th Ave, Ashok
                        Nagar, Chennai, Tamil Nadu 600083
                    </b>
                </p>
            </div>
        </Layout>
    );
};

export default TermsAndConditions;
